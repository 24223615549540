import * as React from "react";
import styled from "styled-components";
import * as style from "./ArtBoard.module.scss";

const ArtBoard = ({ bgcolor, children, link }) => {
  return (
    <div className={style.container}>
      <div className={style.pageLinks}>
        <a
          href={link}
          title="Go to website"
          aria-label="Go to website"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M6 17c2.269-9.881 11-11.667 11-11.667v-3.333l7 6.637-7 6.696v-3.333s-6.17-.171-11 5zm12 .145v2.855h-16v-12h6.598c.768-.787 1.561-1.449 2.339-2h-10.937v16h20v-6.769l-2 1.914z" />
          </svg>
        </a>
      </div>

      <Board bgcolor={bgcolor}>{children}</Board>
    </div>
  );
};

export default ArtBoard;

const Board = styled.div`
  width: clamp(300px, 90vw, 600px);
  margin: auto;
  border: solid 3px #e2edda;
  background-color: ${(p) => p.bgcolor};
  border-radius: 25px;
  box-sizing: border-box;
`;
